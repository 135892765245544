import React from "react"

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

class ContactCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = { form: { name: "", email: "", message: "" }, submitted: false, error: null, submitting: false };
    }

    isFormValid() {
        var form = this.state.form;
        return !!form.name && !!form.email && !!form.message;
    }

    handleSubmit = e => {
        if (!this.isFormValid()) {
            this.setState({ error: "Please fill out all of the fields below" });
        } else {
            this.state.submitting = true;
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "contact", ...this.state.form })
            })
                .then(() => this.setState({ submitted: true, error: null, submitting: false }))
                .catch(() => this.setState({ submitted: false, error: "An error occured, please try again later.", submitting: false }));
        }
        e.preventDefault();
    };

    handleChange = e => {
        this.setState({ ...this.state, form: { ...this.state.form, [e.target.name]: e.target.value } });
    }

    render() {
        const { form: { name, email, message }, submitted, submitting, error } = this.state;
        const { header, paragraph } = this.props;
        return (
            <>
                <form name="contact" data-netlify="true" onSubmit={this.handleSubmit}>
                    <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                                <div className="flex-auto p-5 lg:p-10">
                                    {
                                        (submitted) ? (<>
                                            <h4 className="text-2xl font-semibold text-center">Message Sent!</h4>
                                            <p className="leading-relaxed mt-1 mb-4 text-gray-600 text-center">You will be contacted within 24 hours</p>
                                        </>) : (<>
                                            <h4 className="text-2xl font-semibold">{header}</h4>
                                            <p className="leading-relaxed mt-1 mb-4 text-gray-600">{paragraph}</p>
                                            {!!error ? <p className="leading-relaxed mt-1 mb-4 text-red-700">{error}</p> : null}
                                            <div className="relative w-full mb-3 mt-8">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="name"
                                                >Full Name</label>
                                                <input
                                                    type="text"
                                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                                    placeholder="Full Name"
                                                    value={name}
                                                    name="name"
                                                    onChange={this.handleChange}
                                                    style={{ transition: "all .15s ease" }}
                                                />
                                            </div>

                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="email"
                                                >Email</label>
                                                <input
                                                    type="email"
                                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                                    placeholder="Email"
                                                    value={email}
                                                    name="email"
                                                    onChange={this.handleChange}
                                                    style={{ transition: "all .15s ease" }}
                                                />
                                            </div>

                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="message"
                                                >Message</label>
                                                <textarea
                                                    rows="4"
                                                    cols="80"
                                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                                    placeholder="Type a message..."
                                                    value={message}
                                                    name="message"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="text-center mt-6">
                                                <button
                                                    className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                                    type="submit"
                                                    disabled={submitting}
                                                    style={{ transition: "all .15s ease" }}
                                                >Send Message</button>
                                            </div>
                                        </>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        );
    }
}
export default ContactCard