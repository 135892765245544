import React from "react"


const Section = ({ gray, children }) => {
    return (
      <>
        <section className={(gray ? "bg-gray-300": "") +" py-20"}>
            <div className="container mx-auto px-4">{children}</div>
        </section>
      </>
    );
  }
  
export default Section