import React from "react"

const CenterTitleText = ({ title, paragraph }) => {
    return (
      <>
        <div className="flex flex-wrap text-center justify-center">
            <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">{title}</h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">{paragraph}</p>
            </div>
        </div>
      </>
    );
  }
  
export default CenterTitleText