import React from "react"
import CenterArrayList from "./CenterArrayList";
import CenterTitleText from "./CenterTitleText";
import ContactCard from "./ContactCard"


export default function BuildSomething() {
    return (
        <>
            <section className="pb-20 relative block bg-gray-900">
                <div
                    className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
                    style={{ height: "80px", transform: "translateZ(0)" }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-gray-900 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>

                <div className="container mx-auto px-4 lg:pt-24 pb-64">
                    <CenterTitleText title="Not tech savy?" paragraph="No problem! Some websites require you to dig through code to make changes to the content on your site but if you can fill out a form, you can edit a Mojo Mouse website." />

                    <CenterArrayList items={ [
                        {
                            icon: (<i className="fas fa-medal text-xl"></i>),
                            title: "Satisfaction Guarantee",
                            paragraph: "Revise your website until it best serves your business."
                        },
                        {
                            icon: (<i className="fas fa-check-circle text-xl"></i>),
                            title: "Easy To Use",
                            paragraph: "Content management is simple: enter content and see it live!"
                        },
                        {
                            icon: (<i className="fas fa-cloud text-xl"></i>),
                            title: "Cloud Based",
                            paragraph: "Serverless hosting means there is no hardware to maintain or upgrade."
                        },
                    ]} />
                </div>

                <ContactCard header="Let's build something great!" paragraph="Contact us for a free 30-minute consultation meeting. First, tell us a bit about your business and how we can help you grow." />
            </section>
        </>
    );
}
