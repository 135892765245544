import React from "react"

const CenterArrayList = ({ items }) => {
    return (
        <>
            <div className="flex flex-wrap mt-12 justify-center pb-8">
                {items.map((item, i) => {
                    return (
                        <div key={i} className="w-full lg:w-3/12 px-4 text-center">
                            <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">{item.icon}</div>
                            <h6 className="text-xl mt-5 font-semibold text-white">{item.title}</h6>
                            <p className="mt-2 mb-4 text-gray-500">{item.paragraph}</p>
                        </div>
                    )
                })}
            </div>
        </>
    );
}

export default CenterArrayList